import React from 'react';

import format from 'date-fns/format';
import utils, { isStepResolved } from '../../views/utils';
import { getRightDateFromBackend } from '../../../../../selectors/dates';
import {
  StepIndividualSettings,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useMemberBI } from '../../../../../contexts/BI/BIContext';

import { Modal } from '../../../../../components-shared/Modal';
import { Questionnaire } from '../Questionnaire';

import {
  IQuestionnaireSend,
  IQuestionnaireType,
} from '../Questionnaire/interfaces';
import { st, classes } from './ChallengeQuestionnaire.st.css';

export interface IChallengeQuestionnaireProps {
  isOpened: boolean;
  currentStep: V1ParticipantStep;
  savedQuestionnaireData: any;
  onSaveIntermediateData(
    type: IQuestionnaireType,
    data: IQuestionnaireSend,
  ): void;
  onResolve(): void;
  onCancel?(): void;
}

const getChallengeQuestionnaireType = (
  savedQuestionnaireData: any,
  individualSettings: StepIndividualSettings,
) => {
  let type = null;

  if (
    individualSettings?.showQuiz &&
    !savedQuestionnaireData?.[IQuestionnaireType.QUIZ]
  ) {
    type = IQuestionnaireType.QUIZ;
  } else if (
    individualSettings?.confirmationRequired &&
    !savedQuestionnaireData?.[IQuestionnaireType.CLASSIC]
  ) {
    type = IQuestionnaireType.CLASSIC;
  }

  return type;
};

export const ChallengeQuestionnaire: React.FC<IChallengeQuestionnaireProps> = ({
  currentStep = null,
  isOpened = false,
  savedQuestionnaireData = {},
  onResolve,
  onSaveIntermediateData,
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { memberWebAppButtonClick, quizCheckSnapshot } = useMemberBI();
  const _isStepResolved = isStepResolved(currentStep);
  const individualSettings = currentStep.source?.settings?.general?.individual;
  const type = getChallengeQuestionnaireType(
    savedQuestionnaireData,
    individualSettings,
  );
  const hasNextStep =
    individualSettings?.showQuiz &&
    individualSettings?.confirmationRequired &&
    type === IQuestionnaireType.QUIZ;

  if (!type) {
    return null;
  }

  return (
    <Modal
      opened={isOpened}
      className={st(classes.root)}
      onClose={onCancel}
      sticky={true}
      closeAriaLabel={`Close ${t('questionnaire.title')} modal`}
    >
      <Questionnaire
        key={`questionnaire-${type}`} // component should be re-initialized after type change
        t={t}
        isMobile={isMobile}
        memberWebAppButtonClick={memberWebAppButtonClick}
        quizCheckSnapshot={quizCheckSnapshot}
        stepId={currentStep.id}
        stepName={currentStep.source.settings.general?.description?.title}
        stepDate={
          currentStep.dateFrame?.start
            ? format(
                getRightDateFromBackend(currentStep.dateFrame.start),
                'EEEE, MMMM dd',
              )
            : null
        }
        isStepResolved={_isStepResolved}
        type={type}
        hasNextStep={hasNextStep}
        feedbackItems={
          (type === IQuestionnaireType.QUIZ
            ? currentStep.feedback?.quiz
            : currentStep.feedback?.items) || []
        }
        feedbackSettings={
          (type === IQuestionnaireType.QUIZ
            ? individualSettings?.quizSettings
            : individualSettings?.feedbackSettings) || []
        }
        onSend={async (data) => {
          if (hasNextStep) {
            // Go to next screen. f.ex.: Quiz => Questionnaire, and save the data.
            // Saved data should be cleaned on cancel, on send request and on currentStep change.

            onSaveIntermediateData(type, data);
          } else if (data) {
            // Resolve the step, API request (without data from questionnaire just do nothing).
            // We need the wait here because we should be sure that setState will finished before going to resolve.

            onSaveIntermediateData(type, data);
            await utils.wait();
            onResolve();
          } else {
            // Form hasn't any significant changes, just close it.

            onCancel();
          }
        }}
        onCancel={onCancel}
      />
    </Modal>
  );
};

ChallengeQuestionnaire.displayName = 'ChallengeQuestionnaire';
