import React from 'react';

import uuid from 'uuid/v4';
import {
  ChoiceRightness,
  FeedbackItemSettings,
} from '@wix/ambassador-challenge-service-web/types';

import { IQuestionnaireType } from '../interfaces';
import { ButtonNames } from '../../../../../../contexts/BI/interfaces';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { useGeneralData } from '../../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useMemberBI } from '../../../../../../contexts/BI/BIContext';
import { buildBIFunction } from './fields-bi';

import {
  FileUpload,
  IFileProps,
} from '../../../../../../components-shared/FileUpload';
import { IOutputFile } from '../../../../../../components-shared/FileUpload/types';
import { TextField } from 'wix-ui-tpa/TextField';
import { TextArea } from 'wix-ui-tpa/TextArea';
import { RadioGroup } from '../../RadioGroup/RadioGroup';

import { classes } from './fields.st.css';
import utils from '../utils';
import { imageDataToMediaUrl } from '@wix/challenges-web-library/dist/src';
import { ReactComponent as CheckedIcon } from '../../../../../../assets/icons/checked.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import {
  NOTIFICATION_TYPE,
  SectionNotification,
} from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info.svg';
import { useChallengeData } from '../../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { useUser } from '../../../../../../contexts/User/UserContext';

export const TextQuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: string;
  onChange(id: string, value: any): void;
}> = ({ type, field, currentValue = '', onChange }) => {
  const { t } = useTranslation();
  const { memberWebAppButtonClick } = useMemberBI();
  const sendBI = buildBIFunction({
    fieldId: field.id,
    isFieldRequired: field.isRequired,
    buttonName: ButtonNames.QstnAddTextResponseModal,
    memberWebAppButtonClick,
    clearButtonName: ButtonNames.QstnClearTextResponseModal,
    isClearableField: true,
  });
  const label = utils.getFieldLabel(t, field);

  return (
    <TextArea
      ariaLabel={label}
      className={classes.text}
      placeholder={t('questionnaire.text-field.placeholder')}
      value={currentValue}
      onChange={async (e) => {
        const value = e.target?.value;

        onChange(field.id, value || null);
        await sendBI(!!value);
      }}
      {...({ id: `field-${field.id}` } as any)}
    />
  );
};

export const QuantityQuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: string;
  onChange(id: string, value: any): void;
}> = ({ type, field, currentValue = '', onChange }) => {
  const { t } = useTranslation();
  const { unitName } = field.type.quantity;
  const { memberWebAppButtonClick } = useMemberBI();
  const sendBI = buildBIFunction({
    fieldId: field.id,
    isFieldRequired: field.isRequired,
    buttonName: ButtonNames.QstnAddQuantityResponseModal,
    memberWebAppButtonClick,
    clearButtonName: ButtonNames.QstnClearQuantityResponseModal,
    isClearableField: true,
  });
  const label = utils.getFieldLabel(t, field);

  return (
    <div className={classes.quantity}>
      <TextField
        id={`field-${field.id}`}
        aria-label={`${label} ${unitName}`}
        type="text"
        placeholder={t('questionnaire.number.input.placeholder')}
        value={currentValue}
        onChange={async (e) => {
          const value = e.target?.value;
          const newValue = utils.fixNumber(value);

          if (newValue !== null) {
            onChange(field.id, newValue || null);
            await sendBI(!!newValue);
          }
        }}
        suffix={unitName || null}
      />
    </div>
  );
};

export const MediaQuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: any[];
  onChange(id: string, value: any): void;
  onLoadingChange(value: boolean): void;
}> = ({ type, field, currentValue = [], onChange, onLoadingChange }) => {
  const { t } = useTranslation();
  const { instance } = useGeneralData();
  const { memberWebAppButtonClick } = useMemberBI();
  const { challengeData } = useChallengeData();
  const { participant } = useUser();
  const sendBI = buildBIFunction({
    fieldId: field.id,
    isFieldRequired: field.isRequired,
    buttonName: ButtonNames.QstnAddMediaResponseModal,
    memberWebAppButtonClick,
    clearButtonName: ButtonNames.QstnClearMediaResponseModal,
    isClearableField: true,
  });

  const getFiles = () => currentValue;
  const onUpload = async (file: IOutputFile) => {
    const files = getFiles().concat([
      {
        id: uuid(),
        url: file.file_url,
        width: file.width,
        height: file.height,
        thumbnail:
          file.media_type === 'video'
            ? (() => {
                const img = file.file_output?.image[0];

                return {
                  id: img?.key,
                  url: img?.url,
                  height: img?.height,
                  width: img?.width,
                };
              })()
            : undefined,
        mediaType: file.media_type === 'picture' ? 'image' : file.media_type,
      },
    ]);

    await sendBI(files?.length);
    onChange(field.id, files);
  };
  const onChangeLoading = (isMediaLoading) => {
    onLoadingChange(isMediaLoading);
  };
  const onRemove = async (removedFile: IFileProps) => {
    const files = getFiles().filter((file) => file.id !== removedFile.id);

    await sendBI(files?.length);
    onChange(field.id, files);
  };
  const label = utils.getFieldLabel(t, field);

  return (
    <div>
      <p className={classes.mediaDescription}>
        {t('questionnaire.media.description')}
      </p>
      <FileUpload
        id={field.id}
        instance={instance}
        challengeId={challengeData?.challenge?.id}
        participantId={participant?.id}
        files={getFiles().reverse()}
        onUpload={onUpload}
        onChangeLoading={onChangeLoading}
        onRemove={onRemove}
        maxItems={10}
        ariaLabel={label}
      />
    </div>
  );
};

export enum IChoiceUIRightness {
  RIGHT_CHECKED = 'RIGHT_CHECKED',
  RIGHT_MISSED = 'RIGHT_MISSED',
  WRONG = 'WRONG',
}

export type IChoiceUIRightnessSet = {
  [index: string]: IChoiceUIRightness;
};

export const getChoiceRightness = (
  currentValue: string | string[],
  options: any[],
  isMultiple: boolean,
): IChoiceUIRightnessSet => {
  const rightness: IChoiceUIRightnessSet = {};

  options.forEach((choice) => {
    let optionRightness = null;
    const isOptionChecked = isMultiple
      ? (currentValue || []).includes(choice.id)
      : currentValue === choice.id;

    if (isOptionChecked) {
      if (choice.rightness === ChoiceRightness.RIGHT) {
        optionRightness = IChoiceUIRightness.RIGHT_CHECKED;
      } else if (choice.rightness === ChoiceRightness.WRONG) {
        optionRightness = IChoiceUIRightness.WRONG;
      }
    } else {
      if (choice.rightness === ChoiceRightness.RIGHT) {
        optionRightness = IChoiceUIRightness.RIGHT_MISSED;
      }
    }

    rightness[choice.id] = optionRightness;
  });

  return rightness;
};

export const isRightChoiceAnswer = (rightness: IChoiceUIRightnessSet) => {
  return !Object.keys(rightness).filter((key) =>
    [IChoiceUIRightness.WRONG, IChoiceUIRightness.RIGHT_MISSED].includes(
      rightness[key],
    ),
  ).length;
};

const getChoiceOption = (
  label: string,
  choice: any,
  ind: number,
  isCheckAnswersState: boolean,
  rightness: any,
) => {
  const title = choice?.description?.title;
  const choiceURL = choice?.description?.media?.image?.url;
  const iconURL = choiceURL
    ? imageDataToMediaUrl({
        url: choiceURL,
        width: 200,
        height: 200,
      })
    : null;
  const valueTitle =
    isCheckAnswersState &&
    [
      IChoiceUIRightness.RIGHT_CHECKED,
      IChoiceUIRightness.RIGHT_MISSED,
      IChoiceUIRightness.WRONG,
    ].includes(rightness[choice.id]) ? (
      [
        IChoiceUIRightness.RIGHT_CHECKED,
        IChoiceUIRightness.RIGHT_MISSED,
      ].includes(rightness[choice.id]) ? (
        <CheckedIcon />
      ) : (
        <CloseIcon />
      )
    ) : null;

  return {
    title,
    value: choice.id,
    valueTitle: valueTitle as any,
    hideControl: true,
    iconURL,
    ariaLabel: `${!ind ? label : ''} ${title}`,
    rightness: isCheckAnswersState ? rightness[choice.id] : null,
  };
};

export const ChoiceQuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: string | string[];
  isCheckAnswersState: boolean;
  onChange(id: string, value: any): void;
}> = ({ type, field, currentValue = '', isCheckAnswersState, onChange }) => {
  const { t } = useTranslation();
  const isMultiple = !!field.type?.multiSelect;
  const options =
    field.type?.[isMultiple ? 'multiSelect' : 'multipleChoice']?.choices || [];
  const { memberWebAppButtonClick } = useMemberBI();
  const sendBI = buildBIFunction({
    fieldId: field.id,
    isFieldRequired: field.isRequired,
    buttonName:
      type === IQuestionnaireType.QUIZ
        ? ButtonNames.QuizAddChoiceResponseModal
        : ButtonNames.QstnAddChoiceResponseModal,
    clearButtonName:
      type === IQuestionnaireType.QUIZ
        ? ButtonNames.QuizClearChoiceResponseModal
        : ButtonNames.QstnClearChoiceResponseModal,
    isClearableField: true,
    memberWebAppButtonClick,
  });
  const label = utils.getFieldLabel(t, field);

  const rightness = isCheckAnswersState
    ? getChoiceRightness(currentValue, options, isMultiple)
    : {};
  const isRightAnswer = isCheckAnswersState
    ? isRightChoiceAnswer(rightness)
    : false;

  const isFieldFilled = isMultiple ? !!currentValue?.length : currentValue;
  const isOptionalUnfilled = !field.isRequired && !isFieldFilled;

  return (
    <div className={classes.choice}>
      <RadioGroup
        id={field.id}
        name={field.question}
        options={options.map((choice, ind) => {
          return getChoiceOption(
            label,
            choice,
            ind,
            isCheckAnswersState && !isOptionalUnfilled,
            rightness,
          );
        })}
        selectedValue={currentValue}
        isMobile={false}
        isMultiple={isMultiple}
        onSelect={async (selected) => {
          if (isMultiple) {
            const arrValue = (currentValue || []) as string[];
            const value = selected?.value;

            if (!arrValue.includes(value)) {
              arrValue.push(value);

              await sendBI(true);
              onChange(field.id, arrValue);
            } else {
              arrValue.splice(arrValue.indexOf(value), 1);

              await sendBI(false);
              onChange(field.id, arrValue);
            }
          } else {
            const value = selected?.value;

            onChange(field.id, currentValue === value ? null : value);
            await sendBI(true);
          }
        }}
        withFocusBackground={false}
      />

      {isCheckAnswersState && !isOptionalUnfilled ? (
        <SectionNotification
          type={NOTIFICATION_TYPE.default}
          className={classes.notification}
        >
          <SectionNotification.Icon icon={<InfoIcon />} />
          <SectionNotification.Text>
            {isRightAnswer
              ? field.correctAnswerMessage ||
                t('questionnaire.right-answers.notification.right-default')
              : null}
            {!isRightAnswer
              ? field.wrongAnswerMessage ||
                t('questionnaire.right-answers.notification.wrong-default')
              : null}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </div>
  );
};

// Leftover from the old form.
export const NumericQuestionnaireField: React.FC<{
  type: IQuestionnaireType;
  field: FeedbackItemSettings;
  currentValue: string;
  onChange(id: string, value: any): void;
}> = ({ type, field, currentValue, onChange }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { memberWebAppButtonClick } = useMemberBI();
  const sendBI = buildBIFunction({
    fieldId: field.id,
    isFieldRequired: field.isRequired,
    buttonName:
      type === IQuestionnaireType.QUIZ
        ? ButtonNames.QuizAddChoiceResponseModal
        : ButtonNames.QstnAddChoiceResponseModal,
    memberWebAppButtonClick,
  });
  const label = utils.getFieldLabel(t, field);

  return (
    <div className={classes.numeric}>
      <RadioGroup
        id={field.id || field?.question?.replace(/\s/g, '-')}
        options={[
          {
            title: t('step-feedback.complexity.item.easy'),
            value: 1,
          },
          {
            title: t('step-feedback.complexity.item.right'),
            value: 2,
          },
          {
            title: t('step-feedback.complexity.item.hard'),
            value: 3,
          },
        ].map((choice, ind) => ({
          title: choice.title,
          value: choice.value,
          hideControl: true,
          iconURL: null,
          ariaLabel: `${!ind ? label : ''} ${choice.title}`,
        }))}
        selectedValue={currentValue}
        isMobile={isMobile}
        onSelect={async (selected) => {
          const value = selected?.value;

          await sendBI(true);
          onChange(field.id, currentValue === value ? null : value);
        }}
        withFocusBackground={false}
      />
    </div>
  );
};
