import React from 'react';
import { throttle } from 'lodash';

import { Group } from '@wix/ambassador-social-groups-web/types';
import {
  useTranslation,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { Button as BaseButton, PRIORITY } from 'wix-ui-tpa/Button';
import { imageDataToMediaUrl } from '@wix/challenges-web-library';
import { Tooltip } from 'wix-ui-tpa/Tooltip';

import { getGroup } from '../../services/GroupsService';
import { Spinner } from '../Spinner';

import { GroupIcon } from './GroupIcon';

import { st, classes } from './SocialGroup.st.css';
import { LocationContext } from '../../contexts/Location/LocationContext';
import { getSiteOrigin } from '../../contexts/Location/urlHelpers';
import { Challenges } from '../../editor/types/Experiments';

import { useChallengeData } from '../../contexts/ChallengeDataProvider/ChallengeDataContext';

export type ViewMode = 'compact' | 'list' | 'card';

export interface ISocialGroup {
  Button?: React.FC;
  instance: string;
  groupId: string;
  showButton?: boolean;
  isGroupInstalled: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  className?: string;
  mode?: ViewMode;
}

export const SocialGroup: React.FC<ISocialGroup> = (props) => {
  const [group, setGroup] = React.useState<Group>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sectionWidth, setSectionWidth] = React.useState<number>(0);
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isMobile, language, isPreview, isEditor } = useEnvironment();
  const { location } = React.useContext(LocationContext);
  const groupHref = location?.baseUrl ? `${location?.baseUrl}/` : '';
  const { experiments } = useExperiments();
  const { challengeData } = useChallengeData();

  const isServerlessChallengeExperimentEnabled = experiments.enabled(
    Challenges.enableServerlessChallenge,
  );

  const origin = isEditor || isPreview ? '' : getSiteOrigin(location?.baseUrl);
  const { Button = BaseButton } = props;

  React.useEffect(() => {
    const windowResize = () => {
      setSectionWidth(sectionRef?.current?.offsetWidth || 0);
    };

    setSectionWidth(sectionRef?.current?.offsetWidth || 0);

    window.addEventListener('resize', throttle(windowResize, 200));

    if (!isServerlessChallengeExperimentEnabled) {
      setLoading(true);
      const fetchGroup = async function fetchGroup() {
        const groupResponse = await getGroup(
          props.instance,
          origin,
          props.groupId,
        );

        setGroup(groupResponse);
        setLoading(false);
      };

      fetchGroup();
    }

    return () => window.removeEventListener('resize', windowResize);
  }, [sectionWidth]);

  if (!props.groupId) {
    return null;
  }

  if (loading) {
    return (
      <div className={classes.spinner}>
        <Spinner role="element" />
      </div>
    );
  }

  const getMode = () => {
    if (sectionWidth <= 650) {
      return 'card';
    }

    return props.mode || 'list';
  };

  const WrapTag = props.isGroupInstalled && group ? 'div' : Tooltip;

  if (props.mode === 'compact') {
    return (
      <section
        ref={sectionRef}
        className={st(
          classes.root,
          {
            mode: 'compact',
          },
          props.className,
        )}
      >
        <WrapTag
          content={t('live-site.groups.cta-disabled.tooltip')}
          placement="top"
          appendTo="window"
        >
          <>
            <GroupIcon className={classes.groupIcon} />
            {props.isGroupInstalled && group ? (
              <a
                href={`${groupHref}group/${group?.slug}`}
                className={classes.link}
              >
                {t('live-site.groups.link-cta')}
              </a>
            ) : (
              <span className={classes.link}>
                {t('live-site.groups.link-cta')}
              </span>
            )}
          </>
        </WrapTag>
      </section>
    );
  }

  const groupToUse = isServerlessChallengeExperimentEnabled
    ? challengeData?.groupData.group
    : group;

  return (
    <section
      data-hook="SOCIAL-GROUPS"
      ref={sectionRef}
      className={st(
        classes.root,
        {
          mode: getMode(),
          mobile: isMobile,
        },
        props.className,
      )}
    >
      {!!props.title && <h2 className={classes.title}>{props.title}</h2>}
      {!!props.subTitle && (
        <p className={classes.subtitle}>
          {(props.showButton && !!group && !props.isGroupInstalled) ||
          (!props.showButton && !group) ||
          props.isGroupInstalled
            ? props.subTitle
            : t('live-site.groups.cta-disabled.tooltip')}
        </p>
      )}

      {!!groupToUse && (
        <a
          data-hook="SOCIAL-GROUP-LINK"
          className={`${classes.group} ${
            !props.isGroupInstalled && classes.notInstalled
          }`}
          onClick={(e) => {
            if (!props.isGroupInstalled || !props.showButton) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          href={
            props.isGroupInstalled
              ? `${groupHref}group/${groupToUse.slug}`
              : '#'
          }
        >
          {!!groupToUse?.details?.logo && (
            <div className={classes.media}>
              <img
                className={classes.mediaItem}
                src={imageDataToMediaUrl({
                  url:
                    groupToUse.details.logo?.mediaId.search('media/') >= 0
                      ? groupToUse.details.logo?.mediaId
                      : `media/${groupToUse.details.logo?.mediaId}`,
                  width: isMobile ? 500 : 1000,
                  height: isMobile ? 400 : 800,
                })}
                alt={groupToUse.title}
              />
            </div>
          )}
          <div className={classes.description}>
            <div>
              <h3 className={classes.groupTitle}>{groupToUse.title}</h3>
              <div className={classes.groupInfo}>
                <span className={classes.privacy}>
                  {t(
                    `live-site.groups.privacy.${groupToUse.privacyLevel.toLocaleLowerCase()}`,
                  )}
                </span>
                <span className={classes.memberCount}>
                  {' '}
                  &bull;{' '}
                  {t(`live-site.groups.members-count_icu`, {
                    count: groupToUse.membersCount,
                  })}
                </span>
              </div>
            </div>

            {props.showButton && (
              <WrapTag
                content={t('live-site.groups.cta-disabled.tooltip')}
                placement="top"
                appendTo="window"
              >
                <Button
                  data-hook="SOCIAL-GROUP-BUTTON"
                  disabled={!props.isGroupInstalled}
                  fullWidth={isMobile}
                  priority={PRIORITY.primary}
                  className={classes.groupButtonLayout}
                >
                  {t('live-site.groups.cta')}
                </Button>
              </WrapTag>
            )}
          </div>
        </a>
      )}
    </section>
  );
};

SocialGroup.displayName = 'SocialGroup';
