import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { classes, st } from './SideBarStepView.st.css';
import { Challenges } from '../../../../../editor/types/Experiments';
import { StepViewHidden } from '../StepViewHidden';
import { Ricos } from '../../../../../components-shared/Ricos';
import { ReactComponent as FullscreenOn } from '../../../../../assets/icons/fullscreen-on.svg';
import { ReactComponent as FullscreenOff } from '../../../../../assets/icons/fullscreen-off.svg';

import {
  ListParticipantStepsResponse,
  ParticipantSection,
  V1Challenge,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { Spinner } from '../../../../../components-shared/Spinner';
import utils, { getFlatStepsList } from '../../views/utils';
import { useFullscreen } from '../../../../../contexts/Fullscreen/FullscreenContext';
import { BIContext } from '../../../../../contexts/BI/BIContext';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';

export interface ISideBarStepViewProps {
  challenge: V1Challenge;
  isListParticipantSectionsRequestInProgress: boolean;
  isParticipantStepsLoading: boolean;
  currentStep: V1ParticipantStep;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  goToCurrentStep(e: any): void;
  Breadcrumbs: () => React.ReactElement;
  pageRef: React.RefObject<HTMLElement>;
}

export const SideBarStepView: React.FC<ISideBarStepViewProps> = (props) => {
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const { memberWebAppButtonClick } = React.useContext(BIContext);
  const { t } = useTranslation();
  const {
    challenge,
    isListParticipantSectionsRequestInProgress,
    isParticipantStepsLoading,
    currentStep,
    listParticipantSections,
    participantSteps,
    Breadcrumbs,
    pageRef,
  } = props;
  const isFullscreenEnabled = experiments.enabled(
    Challenges.enableFullscreenMode,
  );
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  return isListParticipantSectionsRequestInProgress ||
    isParticipantStepsLoading ? (
    <Spinner />
  ) : (
    <div
      id={currentStep.id}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {!utils.isStepHidden(
        challenge,
        getFlatStepsList({
          listParticipantSections,
          participantSteps,
        }),
        currentStep,
        experiments.enabled(Challenges.isHiddenStepsEnabled),
      ) ? (
        <>
          <Breadcrumbs />

          {isFullscreenEnabled && !isMobile && (
            <button
              aria-label={t(
                isFullscreen
                  ? 'live-site.sidebar.action-menu.fullscreen.exit'
                  : 'live-site.sidebar.action-menu.fullscreen.enter',
              )}
              onClick={async () => {
                await memberWebAppButtonClick({
                  buttonName: ButtonNames.toggleFullscreenMode,
                });
                await toggleFullscreen(pageRef.current);
              }}
              className={classes.fullscreenButton}
            >
              {isFullscreen ? <FullscreenOff /> : <FullscreenOn />}
            </button>
          )}

          <h3 className={classes.stepViewTitle}>
            {currentStep?.source?.settings?.general?.description?.title}
          </h3>

          <Ricos
            key={currentStep?.id}
            details={
              currentStep?.source?.settings?.general?.description?.details
            }
          />
        </>
      ) : (
        <StepViewHidden
          type="Sidebar"
          date={currentStep?.dateFrame?.start}
          goToCurrentStep={(e) => props.goToCurrentStep(e)}
        />
      )}
    </div>
  );
};
