import React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { convertTextToAccordionAlignment } from '../../../../../services/settingsHelpers';

import { MediaCover } from '../../../../../components-shared/MediaCover';
import { Ricos } from '../../../../../components-shared/Ricos';
import { SectionsAccordion } from '../../../../../components-shared/SectionsAccordion';
import { StepsList } from '../StepsList';

import { st, classes } from './SectionsList.st.css';
import { isStepResolved } from '../../views/utils';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useParticipantSteps } from '../../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { Spinner } from '../../../../../components-shared/Spinner';
import { StepViewHidden } from '../StepViewHidden';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  getSectionIcon,
  isSectionLocked,
} from '../../../../../selectors/sections';

export interface ISectionsListProps {
  sectionIdForceOpened?: string;
  isParticipantCompletedChallenge: boolean;
  onStepTitleClick(id: string): void;
  onNextStepClick?(): void;
}

export const SectionsList: React.FunctionComponent<ISectionsListProps> = (
  props: ISectionsListProps,
) => {
  const {
    sectionIdForceOpened,
    isParticipantCompletedChallenge,
    onStepTitleClick,
    onNextStepClick,
  } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { listParticipantSections } = useSections();
  const { isParticipantStepsLoading } = useParticipantSteps();
  const { experiments } = useExperiments();

  if (isParticipantStepsLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <ul
      data-hook="SECTION-LIST"
      className={st(classes.root, {
        mobile: isMobile,
        contentTextAlignment: settings.get(
          challengeSettings.listLayoutContentAlignment,
        ),
      })}
    >
      {listParticipantSections.map((section) => {
        const steps = section.steps || [];
        const sectionSettings = section.source.settings;
        const key = `section-${section.id}`;
        const resolvedLength = steps.filter((step) =>
          isStepResolved(step),
        ).length;
        const subtitle = t('live.challenges-page.section-steps-subtitle', {
          resolved: resolvedLength,
          total: steps.length,
        });
        const isHideSection =
          experiments.enabled(Challenges.enableDripContent) &&
          isSectionLocked(section);
        const Icon = getSectionIcon(section);

        return (
          <li key={key} className={classes.section} data-id={section.id}>
            <SectionsAccordion
              prefixIcon={<Icon />}
              id={key}
              key={key}
              opened={false}
              forceOpened={
                sectionIdForceOpened && sectionIdForceOpened === section.id
              }
              title={sectionSettings?.description?.title}
              subtitle={subtitle}
              align={convertTextToAccordionAlignment(
                settings.get(challengeSettings.listLayoutContentAlignment),
              )}
              className={classes.sectionAccordion}
            >
              {isHideSection ? (
                <StepViewHidden
                  data-hook="LIST-LAYOUT-HIDDEN-ITEM"
                  type="List"
                  isWeekFixForCTA={false}
                  date={section.transitions[0].waitingDate.startDate}
                  goToCurrentStep={onNextStepClick}
                  titleKey="live.section.section-locked.title"
                  descriptionKey="live.section.section-locked.description"
                />
              ) : (
                <>
                  <MediaCover
                    media={sectionSettings?.description?.media}
                    className={classes.sectionMedia}
                  />

                  <Ricos
                    key={section.id}
                    details={sectionSettings?.description?.details}
                    className={classes.sectionContent}
                  />

                  <StepsList
                    steps={steps}
                    isSPC={true}
                    isStepsLoading={isParticipantStepsLoading}
                    isParticipantCompletedChallenge={
                      isParticipantCompletedChallenge
                    }
                    renderType="AccordionTitle"
                    onStepTitleClick={onStepTitleClick}
                    stepViewClassName={classes.stepView}
                    onNextStepClick={onNextStepClick}
                  />
                </>
              )}
            </SectionsAccordion>
          </li>
        );
      })}
    </ul>
  );
};
SectionsList.displayName = 'SectionsList';
