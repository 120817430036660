import React from 'react';

import {
  IQuestionnaireDefaultProps,
  IQuestionnaireProps,
  IQuestionnaireState,
  IQuestionnaireType,
} from './interfaces';
import { FeedbackItemSettings } from '@wix/ambassador-challenge-service-web/types';
import { ButtonNames } from '../../../../../contexts/BI/interfaces';

import { QuestionnaireField } from './fields';
import {
  Button,
  PRIORITY as ButtonPRIORITY,
  SIZE as ButtonSIZE,
} from 'wix-ui-tpa/Button';

import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';

import { classes, st } from './Questionnaire.st.css';
import qsUtils from './utils';
import { sendFieldsSnapshotBI } from './fields/fields-bi';

export class Questionnaire extends React.Component<
  IQuestionnaireProps,
  IQuestionnaireState
> {
  static displayName = 'Questionnaire';
  static defaultProps: IQuestionnaireDefaultProps = {
    onCancel() {},
  };

  constructor(props: IQuestionnaireProps) {
    super(props);

    this.state = {
      filledQuestionsCount: 0,
      totalQuestionsCount: this.props.feedbackSettings.length,
      isValid: false,
      isMediaLoading: false,
      fields: {},
      mainButtonState: 'Disabled',
      cancelButtonState: 'Disabled',
      isCheckAnswersState: false,
    };

    // We need to dynamically build the initial state for question fields.
    this.props.feedbackSettings.forEach((field: FeedbackItemSettings) => {
      this.state.fields[field.id] = this.props.isStepResolved
        ? qsUtils.getFieldValueFromBackend(this.props.feedbackItems, field)
        : qsUtils.getEmptyValue(field);
    });
  }

  async componentWillMount() {
    await this.updateUIState();

    if (
      this.props.type === IQuestionnaireType.QUIZ &&
      this.props.isStepResolved
    ) {
      this.setState({
        mainButtonState: 'Send',
        isCheckAnswersState: true,
      });
    }
  }

  async updateUIState() {
    await this.checkValidity();
    this.updateProgress();
    this.checkMainButtonState();
    this.checkCancelButtonState();
  }

  onFieldChange = (id, value = null): void => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [id]: value,
        },
        isCheckAnswersState: false, // remove the check state fo any field change
      },
      async () => {
        await this.updateUIState();
      },
    );
  };

  isFieldFilled = (field) => {
    const fieldType = qsUtils.getFieldType(field);
    const fieldValue = this.state.fields[field.id];

    return ['media', 'multiSelect'].includes(fieldType)
      ? (fieldValue || []).length
      : fieldValue !== null;
  };

  updateProgress() {
    this.setState({
      filledQuestionsCount: this.props.feedbackSettings.filter(
        this.isFieldFilled,
      ).length,
    });
  }

  checkValidity() {
    return new Promise((resolve) => {
      this.setState(
        {
          isValid: this.props.feedbackSettings
            .filter((field) => field.isRequired)
            .every(this.isFieldFilled),
        },
        () => resolve(true),
      );
    });
  }

  checkMainButtonState() {
    const { type } = this.props;
    const { isValid, isMediaLoading } = this.state;

    const isAllFieldsOptional =
      this.props.feedbackSettings.filter((field) => field.isRequired).length ===
      0;
    const isAllFieldsUnfilled =
      this.props.feedbackSettings.filter(this.isFieldFilled).length === 0;

    if (!isValid || isMediaLoading) {
      this.setState({
        mainButtonState: 'Disabled',
      });
    } else if (
      type === IQuestionnaireType.QUIZ &&
      !(isAllFieldsOptional && isAllFieldsUnfilled) // for all optional and unfilled set the "Send" state
    ) {
      this.setState({
        mainButtonState: 'Check',
        isCheckAnswersState: false,
      });
    } else {
      this.setState({
        mainButtonState: 'Send',
      });
    }
  }

  checkCancelButtonState() {
    const isSkipAllowed =
      this.props.hasNextStep &&
      !this.props.feedbackSettings.filter((field) => field.isRequired).length &&
      !this.props.feedbackSettings.some(this.isFieldFilled);

    this.setState({
      cancelButtonState: isSkipAllowed ? 'Skip' : 'Cancel',
    });
  }

  async onCheckAnswers() {
    const { type, feedbackSettings, stepId, quizCheckSnapshot } = this.props;
    const { fields } = this.state;

    if (type === IQuestionnaireType.QUIZ) {
      await sendFieldsSnapshotBI(
        feedbackSettings,
        fields,
        stepId,
        quizCheckSnapshot,
      );
    }

    this.setState({
      mainButtonState: 'Send',
      isCheckAnswersState: true,
    });
  }

  onSend = async (): Promise<any> => {
    const { isValid } = this.state;

    if (isValid) {
      const values = {};

      this.props.feedbackSettings.forEach((field) => {
        const fieldType = qsUtils.getFieldType(field);
        const fieldValue = qsUtils.getFieldValueForBackend(
          field,
          this.state.fields,
        );

        if (fieldValue !== null) {
          values[field.id] = {
            type: qsUtils.fieldTypeToFieldName[fieldType],
            value: fieldValue,
          };
        }
      });

      this.props.onSend(values);
    }
  };

  onCancelButtonClick = async () => {
    const { type, memberWebAppButtonClick } = this.props;
    const { cancelButtonState } = this.state;
    const biPrefix = type === IQuestionnaireType.QUIZ ? 'Quiz' : 'Qstn';

    if (cancelButtonState === 'Skip') {
      await memberWebAppButtonClick({
        buttonName: ButtonNames[`${biPrefix}SkipModal`],
      });

      this.props.onSend(null);
    } else {
      await memberWebAppButtonClick({
        buttonName: ButtonNames[`${biPrefix}CancelModal`],
      });

      this.props.onCancel();
    }
  };

  onMainButtonClick = async () => {
    const { type, memberWebAppButtonClick, hasNextStep } = this.props;
    const { mainButtonState } = this.state;
    const biPrefix = type === IQuestionnaireType.QUIZ ? 'Quiz' : 'Qstn';

    if (mainButtonState === 'Check') {
      await memberWebAppButtonClick({
        buttonName: ButtonNames[`${biPrefix}CheckModal`],
      });

      await this.onCheckAnswers();
    } else if (mainButtonState === 'Send') {
      const biButtonName = hasNextStep
        ? `${biPrefix}NextModal`
        : `${biPrefix}SendModal`;

      await memberWebAppButtonClick({
        buttonName: biButtonName,
      });

      await this.onSend();
    }
  };

  render() {
    const {
      t,
      stepName,
      stepDate,
      feedbackSettings = [],
      isMobile,
      type,
      hasNextStep,
    } = this.props;
    const { filledQuestionsCount, totalQuestionsCount, isCheckAnswersState } =
      this.state;
    const { mainButtonState, cancelButtonState } = this.state;

    return (
      <div className={st(classes.root, { mobile: isMobile })}>
        <div className={classes.content}>
          <h1 className={classes.title}>
            {type === IQuestionnaireType.CLASSIC
              ? t('questionnaire.title')
              : t('questionnaire.title-quiz')}
          </h1>
          <p className={classes.description}>
            {t('questionnaire.description')}
          </p>

          <div className={classes.overview}>
            <p>{stepName}</p>
            <p className={classes.date}>{stepDate}</p>
          </div>

          <ul className={classes.questions}>
            {feedbackSettings.map((field) => (
              <QuestionnaireField
                type={type}
                field={field}
                currentValue={this.state.fields[field.id]}
                isCheckAnswersState={isCheckAnswersState}
                onChange={this.onFieldChange}
                onLoadingChange={(isMediaLoading) => {
                  this.setState(
                    {
                      isMediaLoading,
                    },
                    async () => {
                      await this.updateUIState();
                    },
                  );
                }}
              />
            ))}
          </ul>

          <div className={classes.footer}>
            <p className={classes.progress}>
              {t(`questionnaire.progress_icu`, {
                filledQuestionsCount,
                totalQuestionsCount,
              })}
            </p>

            <Button
              className={classes.cancelButton}
              priority={ButtonPRIORITY.basicSecondary}
              size={ButtonSIZE.medium}
              onClick={this.onCancelButtonClick}
            >
              {cancelButtonState === 'Skip'
                ? t('questionnaire.skip')
                : t('questionnaire.cancel')}
            </Button>
            <PreviewActionBlockerContainer
              content={t('action-blocker.preview-mode.submit-feedback')}
              appearance={ActionBlockerAppearance.PopoverAppearance}
            >
              <Button
                disabled={mainButtonState === 'Disabled'}
                className={classes.cta}
                priority={ButtonPRIORITY.primary}
                size={ButtonSIZE.medium}
                onClick={this.onMainButtonClick}
              >
                {mainButtonState !== 'Check'
                  ? hasNextStep
                    ? t('questionnaire.cta-next')
                    : t('questionnaire.cta')
                  : t('questionnaire.check-answers')}
              </Button>
            </PreviewActionBlockerContainer>
          </div>
        </div>
      </div>
    );
  }
}
